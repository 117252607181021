@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url("https://fonts.cdnfonts.com/css/pristina");

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


@media only screen and (max-width: 1500px) {
 html,body{
        font-size: 90% ;
    }
}
@media only screen and (max-width: 600px) {
  html,body{
        font-size: 70%;
    }
}