.overlayImage .title {
	font-size: 3rem;
}
.overlayImage .menuItems {
	font-size: 2rem;
}
.overlayImage .times {
	width: 35%;
}
/* .overlayImage .arrowBtn {
	width: 100%;
} */

@media (max-width: 600px) {
	.overlayImage .title {
		font-size: 1rem;
	}
	.overlayImage .menuItems {
		font-size: 0.8rem;
		margin: 0;
	}
	.overlayImage .times {
		width: 15%;
	}

	.overlayImage .item_logo {
		width: 20%;
		height: auto;
	}
	.overlayImage .arrowBtn {
		width: 25%;
	}
	.overlayImage .arrowItem {
		font-size: 0.8rem;
		margin: 0;
	}
	.overlayImage .closeBtn {
		position: absolute;
		z-index: 5000;
		right: 2rem;
	}
}
