.chefsChoice .swiper {
  /* overflow: hidden; */
  width: 72vw;
  height: 450px;
}

.chefsChoice .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}
.chefschoice .food-title {
  font-style: normal;
  font-weight: normal;
  font-size: 2.583rem;
  /* line-height: 69px; */

  /* identical to box height */

  color: #000000;
}
.chefsChoice .swiper-slide {
    background: rgb(244, 244, 244);;
  border:1px solid #c1bebe;
  color: black;
}
.chefsChoice .swiper-slide.swiper-slide-active {
  background-color: rgb(255, 255, 255);
}
/* .chefsChoice .swiper-slide:nth-child(1n) {
  background-color: rgb(175, 150, 68);
}

.chefsChoice .swiper-slide:nth-child(2n) {
  background-color: rgb(0, 140, 255);
}

.chefsChoice .swiper-slide:nth-child(3n) {
  background-color: rgb(10, 184, 111);
}

.chefsChoice .swiper-slide:nth-child(4n) {
  background-color: rgb(211, 122, 7);
} */
