.footer {
  margin-top: 8rem;
}
.footer .info {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 1.44rem;
  line-height: 31px;
  text-transform: capitalize;

  color: #ffffff;
}
.footer .title {
  font-family: Pristina;
  font-style: normal;
  font-weight: normal;
  font-size: 3.583rem;
  line-height: 69px;

  /* identical to box height */

  color: #ffffff;
}
.footer > div:first-child {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url("/public/image/contacts-Bg.png") !important;
  background-position: center;
  background-size: cover !important;
}
.apps {
  background: #000000;
}
.apps .title {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 24px;
  color: #ffffff;
}
.apps .app {
  width: 11rem;
}

@media (max-width: 600px) {
  .apps .app {
    width: 8rem;
  }
  .social-icon{
    font-size: 3rem;
  }
}
