.ourStory .title {
  font-family: Pristina;
  font-style: normal;
  font-weight: normal;
  font-size: 3.583rem;
  line-height: 69px;
  color: #000000;
}
.ourStory .box {
  width: 38.5rem;
  height: 38.5rem;
  min-height: 40vh;
  background: #c4c4c4;
}
.ourStory .texts {
  font-style: normal;
  font-weight: normal;
  font-size: 1.44rem;
  line-height: 170.5%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #414040;
}
.ourStory .welcome {
  font-family: Pristina;
  font-style: normal;
  font-weight: normal;
  font-size: 2.488rem;
  line-height: 48px;
  text-align: center;
  color: #414040;
}
.ourStory .name {
  font-family: Pristina;
font-style: normal;
font-weight: normal;
font-size: 3rem;
line-height: 48px;

/* identical to box height */

color: #000000;
}
.ourStory .designation {
  font-family: Segoe UI;
font-style: normal;
font-weight: normal;
font-size: 1.44rem;
line-height: 31px;

/* identical to box height */

color: #000000;
}
@media screen and (max-width:600px) {
    .ourStory .box {
  width: 90vw;
  height: auto;
}
}