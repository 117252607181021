.chefschoice {
  min-height: 100vh;
  background: white;
  font-family: "Pristina", sans-serif;
  background: url('/public/image/ChefsSpecial_background.png');
  background-size: cover;
}
.chefschoice .title {
  font-style: normal;
  font-weight: normal;
  font-size: 3.583rem;
  line-height: 69px;

  /* identical to box height */

  color: #000000;
}
.chefschoice .food-title {
  font-style: normal;
  font-weight: normal;
  font-size: 2.583rem;
  /* line-height: 69px; */

  /* identical to box height */

  color: #000000;
}
.chefschoice .sub-title {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 1.728rem;
  line-height: 37px;

  /* identical to box height */
  text-transform: uppercase;

  color: #000000;
}
.chefschoice p {
  font-size: 1.32rem;
}
