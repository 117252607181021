.ourTeamSwiper{
    display:flex;
    justify-content: center;
    align-items: center;
}

.ourTeamSwiper .swiper {
  width: 100%;
  /* height: 100%; */
}

.ourTeamSwiper .swiper-slide {
  text-align: center;
  font-size: 1.1rem;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.ourTeamSwiper .swiper-slide img {
  display: block;
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}
.ourTeamSwiper .swiper-horizontal > .swiper-pagination-bullets,.ourTeamSwiper .swiper-pagination-bullets.swiper-pagination-horizontal,.ourTeamSwiper .swiper-pagination-custom,.ourTeamSwiper .swiper-pagination-fraction {
    /* bottom: -10px !important; */
    left: 0;
    width: 100%;
}

.ourTeamSwiper .swiper-pagination-bullet-active{
background: #1e1e1e;
}