.navBar.sidenav {
  padding: 0 2rem;
}
.offcanvas{
    background-color: #1e1e1e;
    color: white;
    width: 100vw !important;
}
.offcanvas .btn-close{
    background-color: #e4d9d9;
    color: white;
}
.offcanvas-body .nav-link {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  margin-bottom: 1.6rem;
  line-height: 24px;

  color: #ffffff;
}
