

.gallary .swiper {
  width: 90%;
  height: auto;
}

.gallary .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.gallary .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallary .swiper-horizontal > .swiper-pagination-bullets,.gallary .swiper-pagination-bullets.swiper-pagination-horizontal,.gallary .swiper-pagination-custom,.gallary .swiper-pagination-fraction {
    bottom: -40px !important;
    left: 0;
    width: 100%;
}