.banner {
	background: url("/public/image/30overlay.png");
	min-height: 100vh;
	background-size: cover;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
}
.btn-outline-secondary {
	border-color: white !important;
	color: white !important;
}
@media (max-width: 600px) {
	.cafeSubTitle {
		font-size: 13.33px;
	}
}
