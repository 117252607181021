.navBar.header {
  padding: 2rem;
  position: absolute;
  top: 1.4rem;
}
.navBar .nav-link {
  font-family: Segoe UI;
  color: white !important;
  margin-right: 3rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 24px;
  color: #ffffff;
}

@media (max-width: 600px) {
  .navBar .nav-link {
    margin-right: 0rem;
    text-align: end;
  }
}
