.gallary .title {
  font-family: Pristina;
  font-style: normal;
  font-weight: normal;
  font-size: 3.583rem;
  line-height: 69px;
  color: #000000;
  margin-top: 3rem;
}
.gallary .sub-title {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 1.728rem;
  line-height: 37px;
  text-transform: uppercase;
  color: #000000;
}
/* .post .postContent .image{
    background-size: 100% 100% !important; 
    background: coral !important;
} */
.gallary .img-wrapper>div>div{
    overflow: hidden;
}
.gallary .food-img{
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.gallary .food-img:hover {
  transform: scale(1.08);
  cursor: pointer;
}
@media (max-width: 600px){
    .gallary .title {
     margin-top: 9rem !important;
}
}