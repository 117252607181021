.ourmenu {
	font-family: "Pristina", sans-serif;
}
.ourmenu img {
	max-width: 100%;
}
.ourmenu .overlayImage {
	left: 0;
	top: 0;
	z-index: 50;
}
.ourmenu h3 {
	font-style: normal;
	font-weight: normal;
	font-size: 3.583rem;
	line-height: 69px;
	color: #000000;
}
.ourmenu .play-btn {
	top: 50%;
	left: 50%;
	background: white;
	border-radius: 50%;
	transform: translate(-50%, -50%);
}
.ourmenu .play-btn > i {
	font-size: 8.25rem;
}
.ourmenu .play-btn:hover {
	background-color: #d3d3d3;
	cursor: pointer;
}
@media (max-width: 600px) {
	.ourmenu .play-btn > i {
		font-size: 5rem;
	}
}
