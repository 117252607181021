.navBar.headerWithNavBand {
  padding: 0 2rem;
}
.navBar.headerWithNavBand .nav-link {
  /* color: white !important;
  font-style: normal;
  font-weight: bold;
  font-size: 1.728rem !important;
  line-height: 37px;
  margin-right: 3rem;
  font-family: Segoe UI;
  text-decoration: none; */
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 24px;

  color: #ffffff;
}
